import React from "react";
import { isBrowser } from "react-device-detect";
import RichTextEditor from "./RichTextEditor";

function ServiceCard({ item, isFinalButtonTxt, isFinalButtonLink }) {
  return (
    <div className={"col-lg-6"}>
      <div className="beneficioscard_main row">
        <div className={`col-md-4 col-6 beneficioscard_img`} style={{ borderRadius: "10px 0px 0px 10px" }}>
          {item?.image?.url && (
            <img
              src={item?.image?.url}
              style={{
                height: "100%",
                borderRadius: "10px",
              }}
              alt="beneficios tarjetas amex"
            />
          )}
        </div>
        <div style={{ borderRadius: "0px 10px 10px 0px" }} className={`col-6 col-md-8 beneficioscard_info`}>
          <div className="beneficioscard_title">
            <div className="title_icon">
              <img src={item?.icon?.url} alt="tarjetas american express" />
            </div>
            <h4>{item?.name}</h4>
          </div>

          <RichTextEditor className="beneficioscard_content" text={item.benefit.json} />
          {isFinalButtonTxt(item) && (
            <button
              className={"btn final_btn mt-5 btn_finalbutton"}
              onClick={() => window.open(isFinalButtonLink(item), "_blank").focus()}
            >
              {isFinalButtonTxt(item)}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default ServiceCard;
