import React from "react";
import RichTextEditor from "./RichTextEditor";

function Beneficioscontact({ url = "", contentJSON = {} }) {
  return (
    <div className="beneficioscontact_main">
      <div className="beneficioscontact_wrap container">
        <div className="beneficioscontact_icon">{url && <img src={url} alt="telephoneicon" />}</div>
        <div className="beneficioscontact_content pre_wrap">
          <RichTextEditor text={contentJSON} />
        </div>
      </div>
    </div>
  );
}

export default Beneficioscontact;
