import { useState, useEffect } from "react";
import { gql, useApolloClient, useQuery } from "@apollo/client";
import { buildCollectionQueryName, lowerCaseFirstLetter, Query_benefitListCollection } from ".";

export function useFetchBenefitsDataAll({ fetchSpecific = "" }) {
  const { loading, error, data: benefitListCollection } = useQuery(Query_benefitListCollection);
  const [allBenefitsData, setAllBenefitsData] = useState({
    amexDesignersCollection: [],
    businessInsdCollection: [],
    bookingCollection: [],
    globalLoungeCollection: [],
    luxuryRailCollection: [],
    servicioConciergeCollection: [],
    diningProgramCollection: [],
    experiencesCollection: [],
    fastrackCollection: [],
    fineHotelResortsCollection: [],
    amazonCollection: [],
    founderscardCollection: [],
    golfCollection: [],
    loungebuddyCollection: [],
    membresíASixtCollection: [],
    tabletPlusCollection: [],
  });

  const apClient = useApolloClient();

  useEffect(() => {
    if (benefitListCollection) {
      benefitListCollection.benefitListCollection.items[0].benefit.map((item) => {
        const queryName = fetchSpecific
          ? lowerCaseFirstLetter(`${fetchSpecific}Collection`)
          : buildCollectionQueryName(item);

        const query = ({ isContactInfo,isLogo, isFinal, isSecondBlock}) => gql`{
          ${queryName} {
            items {
              title
              subtitle {
                json
              }
              homePage
              category
              creditCardSlider
              banner {
                url
              }
              imagePreviewDesktop {
                url
              }
              imagePreviewMobile {
                url
              }
              shortText {
                json
              }
              ${
                isLogo
                  ? `
                logoImage{
                  url
                }`
                  : ""
              }
              firstTxtBlock {
                json
              }
              topInfo {
                json
              }
              ${
                isSecondBlock
                  ? `
                secondTxtBlock{
                  json
                }
                imageSecondBlock{
                  url
                }`
                  : ""
              }
              ${
                isContactInfo
                  ? `contactImage {
                url
              }
              contactText {
                json
              }`
                  : ""
              }
              ${
                isFinal
                  ? `finalTxt {
                    json
                  }
                  finalButtonTxt {
                    json
                  }
                  finalButtonLink {
                    json
                  }`
                  : ""
              }
              terminosCondiciones {
                json
              }
            }
          }
        }`;

        const keyName = fetchSpecific ? fetchSpecific : item;

        switch (keyName) {
          case "AMEX DESIGNERS":
          case "AmexDesigners":
            apClient.query({ query: query({ isContactInfo: false }) }).then(({ data }) => {
              setAllBenefitsData((prevBenefitsData) => ({
                ...prevBenefitsData,
                amexDesignersCollection: data[queryName].items.map((innerItem) => ({
                  ...innerItem,
                  contentType: "AMEX DESIGNERS",
                })),
              }));
            });
            break;
          case "BUSINESS INSD":
          case "BusinessInsd":
            apClient.query({ query: query({ isLogo: true,isContactInfo: true, isFinal: false }) }).then(({ data }) => {
              setAllBenefitsData((prevBenefitsData) => ({
                ...prevBenefitsData,
                businessInsdCollection: data[queryName].items.map((innerItem) => ({
                  ...innerItem,
                  contentType: "BUSINESS INSD",
                })),
              }));
            });
            break;
          case "BOOKING":
          case "Booking":
            apClient.query({ query: query({ isContactInfo: true,isLogo: true }) }).then(({ data }) => {
              setAllBenefitsData((prevBenefitsData) => ({
                ...prevBenefitsData,
                bookingCollection: data[queryName].items.map((innerItem) => ({ ...innerItem, contentType: "BOOKING" })),
              }));
            });
            break;
          case "GLOBAL LOUNGE":
          case "GlobalLounge":
            apClient.query({ query: query({ isContactInfo: true,isFinal:false }) }).then(({ data }) => {
              setAllBenefitsData((prevBenefitsData) => ({
                ...prevBenefitsData,
                globalLoungeCollection: data[queryName].items.map((innerItem) => ({
                  ...innerItem,
                  contentType: "GLOBAL LOUNGE",
                })),
              }));
            });
            break;
          case "LUXURY RAIL":
          case "LuxuryRail":
            apClient.query({ query: query({ isContactInfo: true, isLogo:true}) }).then(({ data }) => {
              setAllBenefitsData((prevBenefitsData) => ({
                ...prevBenefitsData,
                luxuryRailCollection: data[queryName].items.map((innerItem) => ({
                  ...innerItem,
                  contentType: "LUXURY RAIL",
                })),
              }));
            });
            break;
          case "SERVICIO CONCIERGE":
          case "ServicioConcierge":
            apClient.query({ query: query({ isContactInfo: true }) }).then(({ data }) => {
              setAllBenefitsData((prevBenefitsData) => ({
                ...prevBenefitsData,
                servicioConciergeCollection: data[queryName].items.map((innerItem) => ({
                  ...innerItem,
                  contentType: "SERVICIO CONCIERGE",
                })),
              }));
            });
            break;
          case "DINING PROGRAM":
          case "DiningProgram":
            apClient.query({ query: query({ isContactInfo: true,isFinal: false }) }).then(({ data }) => {
              setAllBenefitsData((prevBenefitsData) => ({
                ...prevBenefitsData,
                diningProgramCollection: data[queryName].items.map((innerItem) => ({
                  ...innerItem,
                  contentType: "DINING PROGRAM",
                })),
              }));
            });
            break;
          case "EXPERIENCES":
          case "Experiences":
            apClient.query({ query: query({ isContactInfo: true, isFinal: false }) }).then(({ data }) => {
              setAllBenefitsData((prevBenefitsData) => ({
                ...prevBenefitsData,
                experiencesCollection: data[queryName].items.map((innerItem) => ({
                  ...innerItem,
                  contentType: "EXPERIENCES",
                })),
              }));
            });
            break;
          case "FASTRACK":
          case "Fastrack":
            apClient.query({ query: query({ isContactInfo: true,isLogo:true, isFinal: false }) }).then(({ data }) => {
              setAllBenefitsData((prevBenefitsData) => ({
                ...prevBenefitsData,
                fastrackCollection: data[queryName].items.map((innerItem) => ({
                  ...innerItem,
                  contentType: "FASTRACK",
                })),
              }));
            });
            break;
            break;
          case "FINE HOTEL & RESORTS":
          case "HotelsResorts":
            apClient.query({ query: query({ isContactInfo: true }) }).then(({ data }) => {
              setAllBenefitsData((prevBenefitsData) => ({
                ...prevBenefitsData,
                fineHotelResortsCollection: data[queryName].items.map((innerItem) => ({
                  ...innerItem,
                  contentType: "FINE HOTEL & RESORTS",
                })),
              }));
            });
            break;
          case "AMAZON":
          case "Amazon":
            apClient.query({ query: query({ isContactInfo: true }) }).then(({ data }) => {
              setAllBenefitsData((prevBenefitsData) => ({
                ...prevBenefitsData,
                amazonCollection: data[queryName].items.map((innerItem) => ({
                  ...innerItem,
                  contentType: "AMAZON",
                })),
              }));
            });
            break;
          case "FOUNDERSCARD":
          case "Founderscard":
            apClient.query({ query: query({ isContactInfo: true, isFinal: false, isLogo:true,isSecondBlock: true}) }).then(({ data }) => {
              setAllBenefitsData((prevBenefitsData) => ({
                ...prevBenefitsData,
                founderscardCollection: data[queryName].items.map((innerItem) => ({
                  ...innerItem,
                  contentType: "FOUNDERSCARD",
                })),
              }));
            });
            break;
          case "GOLF":
          case "Golf":
            apClient.query({ query: query({ isContactInfo: true, isFinal: false, isLogo:true, isSecondBlock: true}) })
              .then(({ data }) => {
                setAllBenefitsData((prevBenefitsData) => ({
                  ...prevBenefitsData,
                  golfCollection: data[queryName].items.map((innerItem) => ({ ...innerItem, contentType: "GOLF" })),
                }));
              });
            break;
          case "LOUNGEBUDDY":
          case "Loungebuddy":
            apClient.query({ query: query({ isContactInfo: false,isLogo:true }) }).then(({ data }) => {
              setAllBenefitsData((prevBenefitsData) => ({
                ...prevBenefitsData,
                loungebuddyCollection: data[queryName].items.map((innerItem) => ({
                  ...innerItem,
                  contentType: "LOUNGEBUDDY",
                })),
              }));
            });
            break;
          case "MEMBRESÍA SIXT":
          case "MembresaSixt":
            apClient.query({ query: query({ isContactInfo: true, isLogo:true, isSecondBlock: true}) }).then(({ data }) => {
              setAllBenefitsData((prevBenefitsData) => ({
                ...prevBenefitsData,
                membresíASixtCollection: data[queryName].items.map((innerItem) => ({
                  ...innerItem,
                  contentType: "MEMBRESÍA SIXT",
                })),
              }));
            });
            break;

          case "TABLET PLUS":
          case "TabletPlus":
            apClient.query({ query: query({ isContactInfo: false }) }).then(({ data }) => {
              setAllBenefitsData((prevBenefitsData) => ({
                ...prevBenefitsData,
                tabletPlusCollection: data[queryName].items.map((innerItem) => ({
                  ...innerItem,
                  contentType: "TABLET PLUS",
                })),
              }));
            });
            break;

          default:
            break;
        }
      });
    }
  }, [benefitListCollection]);

  return [allBenefitsData, setAllBenefitsData, loading, error];
}
