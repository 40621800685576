import React from "react";
import { useQuery } from "@apollo/client";
import { Query_servicioConciergeDataCollectionn } from "../service";
import Slider from "react-slick";
import RichTextEditor from "./RichTextEditor";
import Tablecard from "./Tablecard";
import { isBrowser } from "react-device-detect";
import SecondTxtBlock from "./SecondTxtBlock";
import SecondTxtBlockF from "./SecondTxtBlockF";
import ServiceCard from "./ServiceCard";
import FoneAndHotel from "./FoneAndHotel";
import AmexDesigner from "./AmexDesigner";
import { isMobile } from "react-device-detect";

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

function Beneficioscard({logoImage,firstTxtBlock, shortTextBlock, contentType, imageSecondBlock, secondTxtBlock }) {
  const { data, loading, error } = useQuery(Query_servicioConciergeDataCollectionn, {
    variables: { str: [contentType] },
  });
  if (loading) return <p>loading....</p>;
  if (error) return <div>error... {error.message}</div>;

  const isSingleRow =
    contentType === "BOOKING" ||
    contentType === "FASTRACK" ||
    contentType === "GLOBAL LOUNGE" ||
    contentType === "LOUNGEBUDDY" ||
    contentType === "DINING PROGRAM" ||
    (isBrowser && contentType === "LUXURY RAIL");

  // founder card
  const isInRowMobile = contentType === "LUXURY RAIL";
  const isFineAndHotel = contentType === "FINE HOTEL & RESORTS";
  const isAmazon = contentType === "AMAZON";
  const isAmexDesiners = contentType === "AMEX DESIGNERS";
  const isTabletPuls = contentType === "TABLET PLUS";
  const isFounders = contentType === "FOUNDERSCARD";
  const isServicio = contentType === "SERVICIO CONCIERGE";
  const isSixt =contentType === "MEMBRESÍA SIXT";
  const ismultpalRow = contentType === "EXPERIENCES" || contentType === "FOUNDERSCARD";

  const isFinalButtonTxt = (item) => item?.buttonTxt?.json?.content[0].content[0].value;
  const isFinalButtonLink = (item) => item?.buttonLink?.json?.content[0].content[0].value;
  // contentType === "GLOBAL LOUNGE" || contentType === "LOUNGEBUDDY" || contentType === "LUXURY RAIL";

  const servicename = contentType ? contentType.replace(/ /g, "") : "";

  return (
   
    <div className= {isMobile ? "container beneficioscard_wrap is_mobile":"container beneficioscard_wrap"} id={servicename}>
      <div className="row">
      {logoImage &&   
        <img src={logoImage} className="logo_img" alt="beneficios amex"/>      
      }
        <RichTextEditor className="col-12 beneficioscard_heading mb-5" text={firstTxtBlock} />
        {/* <RichTextEditor
          className="col-12 beneficioscard_content mb-5 text-center shortTextBlock"
          text={shortTextBlock}
        /> */}
        {(secondTxtBlock && !isSixt) && 
          <SecondTxtBlockF secondTxtBlock={secondTxtBlock} imageSecondBlock={imageSecondBlock} />
        }
        
        {isTabletPuls ? (
          <Tablecard data={data.servicioConciergeDataCollection.items} />
        ) : isAmexDesiners ? (
          <AmexDesigner data={data.servicioConciergeDataCollection.items} />
        ) : (
          data.servicioConciergeDataCollection.items.map((item) =>
            item.slidersCollection.items.length > 0 ? (
              <div className="beneficioscard_main row justify-content-center">
                <div
                  className="col-md-3 col-6 d-flex flex-column justify-content-center slider_container_left"
                  style={{ borderRadius: "10px 0px 0px 10px", background: "#F2F2F2" }}
                >
                  <Slider {...settings}>
                    {item.slidersCollection.items.map((innerItem) => (
                      <div>
                        <div className="d-flex justify-content-center ">
                          <img src={innerItem?.url} className="slider_img" style={{ objectFit: "contain" }} />
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
                <div className="col-md-4 col-6 slider_container_right">
                  <div className="beneficioscard_title" style={{ marginLeft: "10px" }}>
                    <h4>{item?.name}</h4>
                  </div>
                  <RichTextEditor className="beneficioscard_content" text={item.benefit.json} />
                </div>
              </div>
            ) : isServicio ? (
              <ServiceCard item={item} isFinalButtonTxt={isFinalButtonTxt} isFinalButtonTxt={isFinalButtonTxt} />
            ) : isFineAndHotel ? (
              <FoneAndHotel item={item} />
            ): isAmazon ? (
              <FoneAndHotel item={item} />
            ) : (
              <div className={isSingleRow ? "col-lg-12" : "col-lg-6"}>
                <div className="beneficioscard_main row">
                  <div
                    style={ismultpalRow ? { borderRadius: isBrowser ? "10px 10px 0px 0px" : "10px 0px 0px 10px" } : {}}
                    className={`${isSingleRow ? "col-md-4 col-12" : "col-md-8 col-6"}  beneficioscard_img`}
                  >
                    {item?.image?.url && (
                      <img
                        src={item?.image?.url}
                        style={{
                          height: isSingleRow || isInRowMobile || ismultpalRow ? "100%" : "164px",
                        }}
                        alt="beneficioscardimg"
                      />
                    )}
                  </div>
                  <div
                    style={ismultpalRow ? { borderRadius: isBrowser ? "0px 0px 10px 10px" : "0px 10px 10px 0px" } : {}}
                    className={`${isSingleRow ? `col-12 ${isBrowser && "p-5"}` : "col-6"} col-md-8 beneficioscard_info`}
                  >
                    <div className="beneficioscard_title">
                      <h4>{item?.name}</h4>
                    </div>

                    <RichTextEditor className="beneficioscard_content" text={item.benefit.json} />
                    {isFinalButtonTxt(item) && (
                      <button
                        className="btn final_btn mt-5"
                        onClick={() => window.open(isFinalButtonLink(item), "_blank").focus()}
                      >
                        {isFinalButtonTxt(item)}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            )
          )
        )}
        {(isSixt) && 
          <SecondTxtBlockF secondTxtBlock={secondTxtBlock} imageSecondBlock={imageSecondBlock} />
        }
      </div>
    </div>
  );
}

export default Beneficioscard;
