import React from "react";
import RichTextEditor from "./RichTextEditor";

function FoneAndHotel({ item }) {
  return (
    <div className="col-lg-6">
      <div className="mb-5 row">
        <div className="col-md-3 col-6 justify-content-center d-flex">
          {item?.image?.url && (
            <img src={item?.image?.url} alt="beneficioscardimg" style={{ height: "auto", width: "auto" }} />
          )}
        </div>
        <div className="col-md-6 col-6">
          <RichTextEditor className="mb-1" pFontFamily="bentonsanslight" text={item.benefit.json} />
        </div>
      </div>
    </div>
  );
}

export default FoneAndHotel;
