import React from "react";
import RichTextEditor from "./RichTextEditor";

const FinalButtonSection = ({ btnText, finalButtonLink, finalTxt }) => {
  return (
    <div className="container mb-5">
      {finalTxt && <RichTextEditor className="final_txt" text={finalTxt} />}
      {btnText && finalButtonLink && (
        <div className="text-center mt-3">
          <button className="btn final_btn finalbuttontxt" onClick={() => window.open(finalButtonLink, "_blank").focus()}>
            {btnText}
          </button>
        </div>
      )}
    </div>
  );
};

export default FinalButtonSection;
