import React from "react";
import RichTextEditor from "./RichTextEditor";

const TerminosCondiciones = ({ terminosCondiciones}) => {
  return (
    <div className="terminos-container bg-gray">
      {terminosCondiciones && <RichTextEditor className="terminos_txt" text={terminosCondiciones} />}
    </div>
  );
};

export default TerminosCondiciones;
