import React from "react";
import Carousel from "./Carousel";

const settings = {
  dots: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3,
        // infinite: true,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
        infinite: true,
      },
    },
  ],
};

function Serviciocardslider({ cards = [], modelname }) {
  return (
    <div>
    {/* Validate in wich benefits the cards sliders doesnt show */}
    {(modelname!='BusinessInsd' && modelname!='Experiences' && modelname!='MembresaSixt') &&   
    <div className="container serviciocardslider" id={modelname}>
      <Carousel settings={settings}>
        {cards.map((item) => (
          <div key={item?.name}>
            <img src={item.creditCard?.url} alt={item?.name} />
          </div>
        ))}
      </Carousel>    
      </div>
    }
    </div>
  );
}

export default Serviciocardslider;
