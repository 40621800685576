import React from "react";

function Tablecard({ data }) {
  return (
    <div>
      <div className="col-12 text-center">
        <button className="Conoce_btn">Conoce los hoteles</button>
      </div>
      <div className="tablet_wrap mt-4">
        <h3 className="tablet_title">
          Accede a mejoras y privilegios con cada reserva{" "}
          <b style={{ fontFamily: "bentonsans_mediumregular" }}>Tablet Plus</b>
        </h3>
        <div className="row align-items-center">
          {data.map((item) => (
            <div className="col-lg-3 col-sm-4 col-6">
              <div className="tablet_content">
                <div className="tablet_img">
                  <img src={item?.image?.url} alt="Designer" />
                </div>
                <div className="amexdesigner_info">
                  <p>{item?.name}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
export default Tablecard;
