import React, { useContext, useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import { useQuery } from '@apollo/client'

import Layout from '../../components/Layout'
import Header from '../../components/Header'
import Servicio from '../../components/Servicio'
import Footer from '../../components/Footer'
import Serviciocardslider from '../../components/Serviciocardslider'
import Beneficioscard from '../../components/Beneficioscard'
import TopInfo from '../../components/TopInfo'
import Beneficioscontact from '../../components/Beneficioscontact'
import RichTextEditor from '../../components/RichTextEditor'
import { lowerCaseFirstLetter, Query_creditCardCollection } from '../../service'
import { isMobile } from 'react-device-detect'
import FinalButtonSection from '../../components/FinalButtonSection'
import { useFetchBenefitsDataAll } from '../../service/fetchBenifitsDataAll'
import TerminosCondiciones from '../../components/TerminosCondiciones'

const Details = (props) => {
  const { id } = props.params
  const [allBenefitsData, loading, error] = useFetchBenefitsDataAll({
    fetchSpecific: id,
  })
  const [selectedData, setSelectedData] = useState({
    creditCardSlider: [],
    contentType: '',
  })
  const {
    data,
    loading: loading2,
    error: error2,
  } = useQuery(Query_creditCardCollection)

  useEffect(() => {
    // if (!selectedData) {
    //   navigate("/");
    // }
    // if (!loading2) {
    switch (id) {
      case 'HotelsResorts':
        setSelectedData(allBenefitsData['fineHotelResortsCollection'][0])
        break
      case 'MembresaSixt':
        setSelectedData(allBenefitsData['membresíASixtCollection'][0])
        break
      default:
        if (allBenefitsData[lowerCaseFirstLetter(`${id}Collection`)]) {
          setSelectedData(
            allBenefitsData[lowerCaseFirstLetter(`${id}Collection`)][0]
          )
        }
        break
    }
  }, [allBenefitsData])

  if (loading && loading2) return <p></p>
  if (error && error2) return <div>error... {error.message}</div>
  return (
    <Layout>
      <Header />
      <Servicio
        url={
          isMobile
            ? selectedData?.imagePreviewMobile?.url
            : selectedData?.imagePreviewDesktop?.url
        }
        title={selectedData?.title?.json}
        subtitle={selectedData?.subtitle?.json}
      />

      <TopInfo
        topInfo={selectedData?.topInfo?.json}
        contentType={selectedData?.contentType}
      />

      <Serviciocardslider
        modelname={id}
        cards={data?.creditCardCollection?.items.filter((item) =>
          selectedData?.creditCardSlider.includes(item.id)
        )}
      />

      <Beneficioscard
        logoImage={selectedData?.logoImage?.url}
        firstTxtBlock={selectedData?.firstTxtBlock?.json}
        shortTextBlock={selectedData?.shortText?.json}
        contentType={selectedData?.contentType}
        secondTxtBlock={selectedData?.secondTxtBlock?.json}
        imageSecondBlock={selectedData?.imageSecondBlock?.url}
      />

      {selectedData?.contactImage?.url && (
        <Beneficioscontact
          url={selectedData?.contactImage?.url}
          contentJSON={selectedData?.contactText?.json}
        />
      )}
      <FinalButtonSection
        btnText={
          selectedData?.finalButtonTxt?.json?.content[0].content[0].value
        }
        finalButtonLink={
          selectedData?.finalButtonLink?.json.content[0].content[0].value
        }
        finalTxt={selectedData?.finalTxt?.json}
      />
      <TerminosCondiciones
        terminosCondiciones={selectedData?.terminosCondiciones?.json}
      />
      <Footer />
    </Layout>
  )
}

export default Details
