import React from "react";
import { useQuery } from "@apollo/client";
import { Query_servicioConciergeDataCollectionn } from "../service";
import RichTextEditor from "./RichTextEditor";
import Tablecard from "./Tablecard";
import { isBrowser } from "react-device-detect";
import SecondTxtBlock from "./SecondTxtBlock";
import ServiceCard from "./ServiceCard";
import FoneAndHotel from "./FoneAndHotel";
import AmexDesigner from "./AmexDesigner";

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

function TopInfo({ topInfo, contentType }) {
  const { data, loading, error } = useQuery(Query_servicioConciergeDataCollectionn, {
    variables: { str: [contentType] },
  });
  if (loading) return <p>loading....</p>;
  if (error) return <div>error... {error.message}</div>;

  const isSingleRow =
    contentType === "BOOKING" ||
    contentType === "FASTRACK" ||
    contentType === "GLOBAL LOUNGE" ||
    contentType === "LOUNGEBUDDY" ||
    contentType === "DINING PROGRAM" ||
    (isBrowser && contentType === "LUXURY RAIL");

  // founder card
  const isInRowMobile = contentType === "LUXURY RAIL";
  const isFineAndHotel = contentType === "FINE HOTEL & RESORTS";
  const isAmexDesiners = contentType === "AMEX DESIGNERS";
  const isTabletPuls = contentType === "TABLET PLUS";
  const isServicio = contentType === "SERVICIO CONCIERGE";
  const ismultpalRow = contentType === "EXPERIENCES" || contentType === "FOUNDERSCARD";

  const isFinalButtonTxt = (item) => item?.buttonTxt?.json?.content[0].content[0].value;
  const isFinalButtonLink = (item) => item?.buttonLink?.json?.content[0].content[0].value;
  // contentType === "GLOBAL LOUNGE" || contentType === "LOUNGEBUDDY" || contentType === "LUXURY RAIL";

  const servicename = contentType ? contentType.replace(/ /g, "") : "";

  return (
    <div className="txt-top">
      <div className="row">
        <RichTextEditor className="col-12" text={topInfo} />
      </div>
    </div>
  );
}

export default TopInfo;
