import React from "react";
import { isBrowser, isMobile } from "react-device-detect";
import RichTextEditor from "./RichTextEditor";

function SecondTxtBlockF({ secondTxtBlock, imageSecondBlock }) {
  return (
    <div className="secondboxF col-12">
      <div className="row">
        <div className="">
        <img src={imageSecondBlock} alt="beneficios amex"/>
          {isBrowser && (
            <div className="col-12 second_block_text">
              <RichTextEditor
                style={{ color: "#006FB8" }}
                pFontFamily="'bentonsans_bookregular"
                text={secondTxtBlock}
                className=""
              />
            </div>
          )}
          
        </div>

        {isMobile && (
          <div className="col-12 second_block_text">
            <RichTextEditor
              style={{ color: "#006FB8" }}
              pFontFamily="'bentonsans_bookregular"
              text={secondTxtBlock}
              className=""
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default SecondTxtBlockF;
