import React from "react";
import RichTextEditor from "./RichTextEditor";

function AmexDesigner({ data = [] }) {
  return (
    <div className="amexdesigner_wrap">
      <h3 className="amexdesigner_tilte">
        Conoce más de nuestros #AMEX<span>Designers</span> y de tus beneficios en sus tiendas
      </h3>
      <div className="row align-items-center">
        {data.map((item) => (
          <div className="col-md-3 col-4 ">
            <div className="amexdesigner_content">     
            
            {item?.buttonLink?.json?.content[0].content[0].value ? 
              <div className="amexdesigner_img">
              <a  href={item?.buttonLink?.json?.content[0].content[0].value} target="_blank"><img src={item?.image?.url} alt="Designer img" /></a>            
              </div>
              :
              <div className="amexdesigner_img">
              <a  href="#"><img src={item?.image?.url} alt="Designer img" /></a>            
              </div>
            }
              

              <div className="amexdesigner_info">
                <h4>{item?.name}</h4>
                <RichTextEditor className="" text={item.benefit.json} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AmexDesigner;
